<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="100px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="巡检次数" prop="patrolTimes">
            <el-input v-model="form.patrolTimes" disabled></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="content">
            <el-input
              type="textarea"
              rows="5"
              v-model="form.content"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="检查表题目">
            <el-card shadow="never">
              <el-button size="mini" @click="addQuestionType">
                添加类型
              </el-button>
              <el-divider></el-divider>
              <el-tabs
                v-model="currentQuestionTypeName"
                :tab-position="tabPosition"
                closable
                @tab-remove="removeQuestionType"
              >
                <el-tab-pane
                  v-for="typedQuestionList in form.typeList"
                  :key="typedQuestionList.typeName"
                  :label="typedQuestionList.typeName"
                  :name="typedQuestionList.typeName"
                >
                  <span slot="label">
                    <el-input
                      v-model="typedQuestionList.typeName"
                      style="width: 80%"
                      placeholder="请输入内容"
                    ></el-input>
                  </span>
                  <el-card
                    class="box-card-area"
                    style="margin-bottom: 10px"
                    :hehe="typedQuestionList.length"
                    v-for="(item, index) in typedQuestionList.questionList"
                    :key="index"
                    shadow="never"
                  >
                    <el-row>
                      <el-col :span="20">
                        <el-form-item label="题目">
                          <el-input v-model="item.title"></el-input>
                        </el-form-item>
                        <el-form-item label="标准描述">
                          <quill-editor v-model="item.standardDesc" :options="editorOption">
                          </quill-editor>
                        </el-form-item>
                        <el-col :span="12">
                          <el-form-item label="最小得分">
                            <el-input-number
                              style="width:100%"
                              v-model="item.scoreMin"
                            ></el-input-number>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="最大得分">
                            <el-input-number
                              style="width:100%"
                              v-model="item.scoreMax"
                            ></el-input-number>
                          </el-form-item>
                        </el-col>
                      </el-col>
                      <el-col :span="4">
                        <el-button style="display: block; margin: 0 auto" type="danger" @click="onRemove(index)">移除</el-button>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-row>
                    <el-col :span="12">
                      <el-button style="display: block; margin: 0 auto" type="primary" @click="addQuestion()">添加检查项</el-button>
                    </el-col>
                    <el-col :span="12">
                      <el-button style="display: block; margin: 0 auto" type="primary" @click="addQuestionWithClass()">通过题库添加检查项</el-button>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </el-form-item>

          <el-form-item style="overflow: hidden">
            <el-button type="primary" @click="onSubmit" style="float: right"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-dialog
      title="类型名称"
      width="20%"
      :visible.sync="questionTypeNameDialogFormVisible"
    >
      <el-form :model="form">
        <el-form-item>
          <el-input
            v-model="currentQuestionTypeName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="questionTypeNameDialogFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="doAddQuestionType">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      width="20%"
      :visible.sync="removeQuestionTypeDialogFormVisible"
    >
      <p>是否确认删除{{ currentQuestionTypeName }}！</p>
      <p>注意，该类型下的所有题目都将被删除</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="removeQuestionTypeDialogFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="doRemoveQuestionType"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="添加题目" width="80%" :visible.sync="classQuestionVisible">
      <el-row>
        <el-col :span="4">
          <el-tree :data="classList" :props="{children: 'children', label: 'label'}" @node-click="nodeClick">
            <span class="custom-tree-node" slot-scope="{node}" style="width: 100%">
                <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-col>
        <el-col :span="19" :offset="1">
          <el-row style="margin-top: 5px">
            <el-col :span="24">
                <div class="cardTitle">{{secondClassName}}</div>
            </el-col>
            <el-col :span="24">
                <el-table :data="questionList" @selection-change="handleSelectionChange">
                  <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="题目" prop="title"></el-table-column>
                    <el-table-column label="得分标准">
                        <template slot-scope="scope">
                            <p v-html="scope.row.standardDesc"></p>
                        </template>
                    </el-table-column>
                    <el-table-column label="最小得分" prop="scoreMin"></el-table-column>
                    <el-table-column label="最大得分" prop="scoreMax"></el-table-column>
                </el-table>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="classQuestionVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddQuestionWithClass">确 定</el-button>
      </div>
    </el-dialog>
    <el-backtop></el-backtop>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card-question {
  width: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      image_: require("../../assets/icons-plus.png"),
      tabPosition: "left",
      form: {
        surveyGuid: "",
        name: "",
        patrolTimes: "",
        content: "",
        typeList: [],
      },
      currentQuestionTypeName: "",
      activeName: "1",
      userInfo: {},
      allDeptPosition: [],
      rules: {},
      typeList: [],
      questionTypeNameDialogFormVisible: false,
      removeQuestionTypeDialogFormVisible: false,
      classQuestionVisible: false,
      editorOption: {
        placeholder: '请输入正文......',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [
              { 'list': 'ordered'},
              { 'list': 'bullet'}
            ],
            [
              { 'size': ['small', false, 'large', 'huge'] }
            ],
            [
              { 'header': [1, 2, 3, 4, 5, 6, false] }
            ],
            [
              { 'color': [] }
            ],
            [
              { 'align': [] }
            ]
          ]
        }
      },
      classList: [],
      secondClassName: '',
      classGuid: '',
      questionList: [],
      classQuestion: []
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getSurveyDetail(self.$route.query.guid || null);
  },
  methods: {
    getSurveyDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/patrol-survey/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            self.form.surveyGuid = response.data.data.guid;
            self.form.name = response.data.data.name;
            self.form.patrolTimes = response.data.data.patrolTimes;
            self.form.content = response.data.data.content;
            self.form.typeList = response.data.data.typeList;
            self.currentQuestionTypeName = self.form.typeList[0].typeName
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      } else {
        self.form = {
          name: "",
        };
      }
    },
    resetOrderNum() {
      var i = 0;
      let self = this;
      self.form.questionList.forEach((element) => {
        element.orderNum = ++i;
      });
    },
    onSubmit() {
      let self = this;
      let data = JSON.parse(JSON.stringify(self.form));
      self.$axios
        .post("/patrol-question/saveOrUpdate", JSON.stringify(data), {
          headers: {
            "content-type": "application/json",
            token: self.userInfo.token,
          },
        })
        .then(function() {
          self.$message({
            message: "操作成功",
            type: "success",
          });
          self.$router.push({ name: "patrol_survey_list" });
        })
        .catch(function(error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.account || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/staff/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "staff_list" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    onRemove(index) {
      console.log(index);
      let self = this;
      let _index = self.findQuestionTypeIndex();
      self.form.typeList[_index].questionList.splice(index, 1);
    },
    plusClick() {
      let self = this;
      let params = {};
      var index = self.form.questionList.findIndex((o) => o.title == "");
      if (index != -1) {
        self.$message({
          message: "请先完成已添加的项",
          type: "warning",
        });
        return false;
      } else {
        params.guid = "";
        params.title = "";
        params.orderNum = self.form.questionList.length + 1;
        params.typeName = "";
        params.scoreMax = 0;
        params.scoreMin = 0;
        params.surveyGuid = self.$route.query.guid;
        self.form.questionList.push(params);
      }
    },
    addQuestionType() {
      let self = this;
      self.currentQuestionTypeName = "";
      self.questionTypeNameDialogFormVisible = true;
    },
    doAddQuestionType() {
      let self = this;
      let newQuestionType = {
        typeName: self.currentQuestionTypeName,
        questionList: [],
      };
      self.form.typeList.push(newQuestionType);
      self.questionTypeNameDialogFormVisible = false;
    },
    removeQuestionType(questionType) {
      let self = this;
      console.log("removeQuestionType");
      self.currentQuestionTypeName = questionType;
      self.removeQuestionTypeDialogFormVisible = true;
    },
    doRemoveQuestionType() {
      let self = this;
      console.log(self.form.typeList);
      self.form.typeList = self.$underscore.filter(self.form.typeList, function(
        _el
      ) {
        return _el.typeName != self.currentQuestionTypeName;
      });
      console.log(self.form.typeList);
      if (self.form.typeList.length == 0) {
        self.currentQuestionTypeName = "";
      } else {
        self.currentQuestionTypeName = self.form.typeList[0].typeName;
      }
      self.removeQuestionTypeDialogFormVisible = false;
    },
    calculateCurrentQuestionTypeName() {
      let self = this;
      return self.currentQuestionTypeName || self.form.typeList[0].typeName;
    },
    addQuestion() {
      let self = this;
      let newQuestion = { title: "", scoreMax: "", scoreMin: "" };
      let _index = self.findQuestionTypeIndex();
      console.log("_index = ", _index);
      self.form.typeList[_index].questionList.push(newQuestion);
    },
    findQuestionTypeIndex() {
      let self = this;
      let _index = 0;
      self.$underscore.each(self.form.typeList, function(_el, index) {
        console.log("index = ", index);
        if (_el.typeName == self.currentQuestionTypeName) {
          _index = index;
        }
      });
      return _index;
    },
    // 点击通过题库添加检查项
    addQuestionWithClass() {
      let self = this
      self.getClassTreeStructure()
      self.questionList = []
      self.secondClassName = ''
      self.classQuestionVisible = true
    },  
    // 获取分类树状结构
    getClassTreeStructure() {
      let self = this;
      self.$axios.get('/indexbase-category/tree',{
          headers: { token: self.userInfo.token }
      })
      .then(function(res) {
          self.classList = res.data.data
      })
      .catch(function(err) {
          self.$message({
              message: "分类列表获取失败：" + err.response.data.message,
              type: "warning"
          });
      })
    },
    // 展示题目
    nodeClick(data,node) {
      let self = this
      if(node.level == 2) {
          self.secondClassName = data.label
          self.classGuid = data.value
          self.getQuestion(self.classGuid)
      }
    },
    // 获取题目
    getQuestion(guid) {
      let self = this
      self.$axios.get('/indexbase-question/list?categoryGuid='+guid,{
          headers: { token: self.userInfo.token }
      })
      .then(function(res) {
          self.questionList = res.data.data
          console.log(guid);
          console.log(self.questionList);
      })
      .catch(function (error) {
          self.$message({
          message: "题目列表获取失败：" + error.response.data.message,
          type: "warning",
          });
      });
    },
    // 选中题目数据
    handleSelectionChange(val) {
      let self = this
      self.classQuestion = val
      console.log(self.classQuestion);
    },
    // 确认通过题库添加检查项
    doAddQuestionWithClass() {
      let self = this
      let newQuestion = self.classQuestion
      let _index = self.findQuestionTypeIndex();
      console.log("_index = ", _index);
      newQuestion.forEach(item => {
        self.form.typeList[_index].questionList.push(item);
      })
      console.log(self.form);
      self.classQuestionVisible = false
    },
  },
};
</script>
